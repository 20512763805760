<template>
	<div>
		<list-template
				:current-page="page"
				:is-check-input-box="false"
				:loading="loading"
				:table-config="tableConfig"
				:table-data="tableData"
				:total="total"
				@onAdd="$router.push('./add')"
				@onBatchImport="()=>{dialogVisible=true;errorMessage=''}"
				@onChangePage="handleCurrentChange"
				@onExportData="exportData"
				@onHandle="handleEdit"
				@onSearch="onSearch"
		>
			<div slot='title'>
				<searchBlock :search-config='searchConfig' @onSearch='onSearch'></searchBlock>
			</div>
		</list-template>
		<el-dialog :visible.sync="dialogVisible" title="批量导入">
			<div>
				<span style="display: inline-block;width: 180rem">请下载导入数据模板</span>
        <el-button size="small" @click="onDownload('/bf-static/school-system/preview/线下已缴.xlsx')">下载【线下已缴】模板</el-button>
        <el-button size="small" @click="onDownload('/bf-static/school-system/preview/线上待缴.xlsx')">下载【线上待缴】模板</el-button>
			</div>
			<div style="margin: 24rem 0;display: flex;align-items: center">
				<span style="display: inline-block;width: 180rem">请上传导入文件</span>
				<el-upload :action="$_register.defaults.baseURL + '/api/recruit-v2/offline-fresh/template'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
									 :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="fresh_paid_file">
					<el-button size="small" type="primary">上传【线下已缴】文件</el-button>
				</el-upload>
				<el-upload :action="$_register.defaults.baseURL + '/api/recruit-v2/offline-fresh/template'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
									 :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="fresh_pending_file" style="margin-left: 8px">
					<el-button size="small" type="primary">上传【线上待缴】文件</el-button>
				</el-upload>
			</div>
			<div style="padding-left: 178rem">
				<span style="display: inline-block;color: #F25858;background: #FFEAEA;border-radius: 6rem;padding: 4rem 12rem;margin-bottom: 24rem">
					<i class="el-icon-warning-outline"></i>
					【特殊减免】优惠不支持批量导入！
				</span>
				<br>
				<span style="color: red">{{ errorMessage }}</span>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"
import searchBlock from "@/components/SearchBlock.vue";

export default {
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	components: {searchBlock, ListTemplate},
	data() {
		return {
			loading: true,
			search: {},
			searchConfig: [{
				prop: 'student_name',
				placeholder: '搜索学生姓名'
			}, {
				prop: 'school_id',
				placeholder: '筛选报名校区',
				options: [],
				tag: 'select',
				label: 'school_name',
				value: 'school_id',
			}, {
				prop: 'grade_id',
				placeholder: '筛选入学年级',
				options: [],
				tag: 'select',
				label: 'grade_name',
				value: 'grade_id',
			}, {
				prop: 'student_type',
				placeholder: '筛选学生类型',
				options: [],
				tag: 'select',
				label: 'student_type_name',
				value: 'student_type',
			}, {
				prop: 'business_line',
				placeholder: '筛选所属业务线',
				options: [],
				tag: 'select',
				label: 'business_line_name',
				value: 'business_line',
			}, {
				prop: 'payment_type',
				placeholder: '筛选缴费方式',
				options: [],
				tag: 'select',
				label: 'payment_type_name',
				value: 'payment_type',
			}, {
				prop: 'living_pay',
				placeholder: '筛选是否缴纳生话费',
				options: [],
				tag: 'select',
				label: 'living_pay_name',
				value: 'living_pay',
			}, {
				prop: 'approve_status',
				placeholder: '筛选审批状态',
				options: [],
				tag: 'select',
				label: 'approve_status_name',
				value: 'approve_status',
			}, {
				prop: 'fill_in',
				placeholder: '筛选家长填写状态',
				options: [],
				tag: 'select',
				label: 'fill_in_name',
				value: 'fill_in',
			}],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [
				{prop: 'student_name', label: '学生姓名'},
				{prop: 'student_type', label: '学生类型'},
				{prop: 'business_line', label: '所属业务线'},
				{prop: 'school_name', label: '报名校区'},
				{prop: 'grade_name', label: '入学年级'},
				{prop: 'payment_type', label: '缴费方式'},
				{prop: 'living_pay', label: '是否缴纳高一学年生活费'},
				{prop: 'creator', label: '录入人'},
				{
					prop: 'approve_status', label: '审批状态', render: (row) => {
						return `<div style="color: ${row['approve_status'] === '待审批' ? '#F09D12' : row['approve_status'] === '已通过' ? '#23C343' : '#EA0000'}">${row['approve_status']}</div>`
					}
				},
				{prop: 'fill_in', label: '家长是否已填写'},
				{
					prop: 'handle',
					label: '操作',
					width: '120rem',
					handle: true,
					render() {
						return ['编辑', '二维码']
					}
				}
			],
			dialogVisible: false,
			errorMessage: '',
			url: {
				qr_code: ''
			}
		}
	},
	created() {
		this.$store.commit('setPage', 1)
		this.getData()
		this.$_register.get('api/recruit-v2/offline-fresh/config').then(res => {
			const dataMap = {
				school_data: 1,
				grade_add: 2,
				student_type_list: 3,
				business_line_list: 4,
				payment_type_list: 5,
				living_pay_list: 6,
				approve_status_list: 7,
				fill_in_list: 8,
			};
			Object.entries(dataMap).forEach(([key, index]) => {
				this.searchConfig[index].options = res.data.data[key] || [];
			});
		})
	},
	activated() {
		this.getData()
	},
	computed: {
		...mapState(['page'])
	},
	methods: {
		getData() {
			this.loading = true
			let params = {...this.search, page: this.page}

			this.tableData = []
			this.$_register('api/recruit-v2/offline-fresh/list', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => {
				this.loading = false
			})
		},

		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData(val)
		},

		handleEdit(row, text) {
			switch (text.type) {
				case 'view':
					this.$router.push('./edit?id=' + row.id)
					break

			}
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			this.$store.commit('setPage', val)
			this.getData()
		},

		// 导出数据
		exportData() {
			let params = {...this.search}
			this.$_register('api/recruit-v2/offline-fresh/export', {params}).then(res => {
				this.$tools.download('线下补录名单', res.data)
			})
		},

		onDownload(val) {
			location.href = process.env.VUE_APP_URL_PROD_BASE + val;
		},
		onSuccess(res) {
			if (res.status === 0) {
				this.$message.success('上传成功')
				this.errorMessage = ''
			} else this.errorMessage = res.msg
		},
		beforeUpload(file) {
			const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
			const extension = testmsg === 'xls'
			const extension2 = testmsg === 'xlsx'
			if (!extension && !extension2) {
				this.$message({
					message: '上传文件只能是xls、xlsx格式!',
					type: 'warning'
				})
			}
			return extension || extension2
		}
	},
}
</script>
<style lang="scss" scoped>
.role-list {
	padding-top: 24rem;
}

.button {
	border-radius: 4rem;
	box-sizing: border-box;
	height: 34rem;
	margin-bottom: 30rem;
	margin-left: 34rem;
	opacity: 1;
	width: 90rem;
}

.table {
	margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	box-sizing: border-box;
	color: #333333;
	font-size: 14rem;
	font-weight: normal;
	height: 48rem;
	text-align: left;
}

.header {
	color: #333333;
	font-size: 16rem;
	font-weight: bold;
	left: 300rem;
	letter-spacing: 3.5rem;
	line-height: 24rem;
	position: fixed;
	top: 120rem;
}

.el-button--text {
	color: #3491fa;
	font-size: 14rem;
	font-weight: normal;
	margin-right: 40rem;
}
</style>
